import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

// Customizable Area Start
interface CandidateApiListData {
    created_assessment_count: number;
    completed_assessment_count: number;
    completion_percentage: number;
    total_percentage_score: number;
    assessments: {
        candidate_id: number;
        first_name: string;
        assessment_id: number;
        assessment_title: string;
        total_questions: number;
        correct_answers: number;
        wrong_answers: number;
        unattempted_questions: number;
        percentage_score: number;
        objective: string;
        status: string;
    }[];
    pagination: {
        current_page: number;
        total_pages: number;
        total_count: number;
        per_page: string;
        prev_page: number;
        next_page: number;
    };
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    SeachText: string,
    token: string,
    page: number;
    perPage: number;
    AssessmentList: {
        CandidateId: number;
        FirstName: string;
        AssessmentId: number;
        AssessmentTitle: string;
        TotalQuestions: number;
        CorrectAnswers: number;
        WrongAnswers: number;
        UnattemptedQuestions: number;
        PercentageScore: number;
        objective: string;
        status: string;
    }[];
    TotalPercentageScore: number,
    CompletionPercentage: number,
    CompletedAssessmentCount: number
    CreatedAssessmentCount: number,
    selectedLanguage: string,
    ActiveTab: string,
    IsPopUpOpen: boolean,
    IsLoading: boolean,
    CandidateName: string,
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CandidateDashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    GetAllAssessmentApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            SeachText: "",
            token: "",
            page: 1,
            perPage: 10,
            AssessmentList: [],
            TotalPercentageScore: 0,
            CompletionPercentage: 0,
            CompletedAssessmentCount: 0,
            CreatedAssessmentCount: 0,
            selectedLanguage: "",
            ActiveTab: "All",
            IsPopUpOpen: false,
            IsLoading: true,
            CandidateName: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.GetResponseOfCandidateList(apiRequestCallId, responseJson);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const tokenMeta = await getStorageData('signInResponse', true);
        const language = await getStorageData("language") || "English";
        this.setState({
            token: tokenMeta?.meta?.token,
            selectedLanguage: language
        })
        this.GetAllCandidate()

    }

    GetAllCandidate = (status?:string) => {
        this.setState({
            IsLoading: true
        })
        const headers = {
            "token": this.state.token
        };

        let queryParam = "";

        if (this.state.SeachText) {
            queryParam += `search=${this.state.SeachText}&`
        }

        if (status) {
            queryParam += `status=${status}&`
        }

        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAllAssessmentApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.CandidateListApiEndPoint}?${queryParam}&page=${this.state.page}&per_page=${this.state.perPage}`
        );
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }

    GetResponseOfCandidateList = (apiRequestCallId: string, response: CandidateApiListData) => {
        if (this.GetAllAssessmentApiCallId === apiRequestCallId) {
            const ListData = response.assessments?.map((item) => {
                return {
                    CandidateId: item.candidate_id,
                    FirstName: item.first_name,
                    AssessmentId: item.assessment_id,
                    AssessmentTitle: item.assessment_title,
                    TotalQuestions: item.total_questions,
                    CorrectAnswers: item.correct_answers,
                    WrongAnswers: item.wrong_answers,
                    UnattemptedQuestions: item.unattempted_questions,
                    PercentageScore: item.percentage_score,
                    objective: item.objective,
                    status: item.status
                }
            })
            this.setState({
                AssessmentList: ListData,
                TotalPercentageScore: response.total_percentage_score,
                CompletionPercentage: response.completion_percentage,
                CompletedAssessmentCount: response.completed_assessment_count,
                CreatedAssessmentCount: response.created_assessment_count,
                CandidateName: `${ListData[0]?.FirstName}`,
                IsLoading: false
            })
        }
    }

    handleCondition = (condition: boolean, TrueCondition: string, FalseCondition: string) => {
        return condition ? TrueCondition : FalseCondition
    }

    HandelStatus = (TabText: string, value: string) => {
        this.setState({
            ActiveTab: TabText,
            IsPopUpOpen: false
        },()=>{
            this.GetAllCandidate(value)
        })
    }

    HandelPopUpOpenAndClose = () => {
        this.setState({
            IsPopUpOpen: !this.state.IsPopUpOpen
        })
    }

    RenderPopList = () => {
        let data = [{ name: "All", value: "" }, { name: "New", value: "created" }, { name: "Completed", value: "completed" }]
        return data
    }

    // Customizable Area End
}