// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

import React, { ReactNode } from "react";
import { i18n } from "i18next";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    children?: ReactNode;
    selectedMenu?: string;
    i18n?: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    selectedLanguage: string;
    calendarOpen: boolean,
    selectedDate: null | [Date, Date];
    tempDate: null | [Date, Date];
    AllParameterNames: string[];
    CriticalTableData: Array<{
        ID: string;
        position_rank: string;
        job_title: string;
        total_score: string;
        financial_impact: string;
        reputation: string;
        scarcity: string;
        [key: string]: string;
    }>;
    TotalAssessments: number;
    isLoading: boolean;
    searchText: string;
    anchorRef: HTMLElement | null;
    OpenIndex: number;
    PendingAssessments: number;
    page: number;
    perPage: number;
    PaginationPageCount: number;
    TotalRecord: number;
    CurrentFilterStatus: string;
    FilterAssessmentDropDown: boolean;
    status: string;
    DeleteQuestionModel: boolean;
    AssessmentId: string;
    Total_Positions: Number;
    Critical_Rank_A: Number;
    Critical_Rank_B: Number;
    Critical_Rank_C: Number;
    actionMenuOpenIndex: number | null;
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CriticalPositionDashbordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    GetAllAssessementApiCallId: string = "";
    GetCriticalDashboardApiCallId: string = "";
    DeleteQuestionApiId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            token: '',
            selectedLanguage: '',
            calendarOpen: false,
            selectedDate: null,
            tempDate: null,
            CriticalTableData: [],
            TotalAssessments: 0,
            isLoading: false,
            anchorRef: null,
            actionMenuOpenIndex: null,
            OpenIndex: -1,
            PendingAssessments: 0,
            searchText: '',
            AllParameterNames: [],
            page: 1,
            perPage: 5,
            PaginationPageCount: 0,
            TotalRecord: 0,
            FilterAssessmentDropDown: false,
            CurrentFilterStatus: "All Assessments",
            status: "",
            DeleteQuestionModel: false,
            AssessmentId: "",
            Total_Positions: 0,
            Critical_Rank_A: 0,
            Critical_Rank_B: 0,
            Critical_Rank_C: 0
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }


    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.GetCriticalPositionApiResponse(apiRequestCallId, responseJson);

        }
        // Customizable Area End
    }

    async componentDidMount() {
        const tokenMeta = await getStorageData('signInResponse', true);
        this.setState({
            token: tokenMeta?.meta?.token
        })
        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
        this.AllCriticalPostionApiCall();
    }

    GetCriticalPositionApiResponse = (APiCallId: string, responseJson: any) => {
        if (APiCallId === this.GetCriticalDashboardApiCallId) {
            if (responseJson.critical_positions?.data.length > 0) {
                let allParameterNames: string[] = [];
    
                const CriticalTableData = responseJson.critical_positions.data.map(
                    (item: { id: string; attributes: any }) => {
                        const attributes = item.attributes;
                        const criticalParams = attributes.critical_parameters || [];
    
                        let rawScore = parseFloat(attributes.total_score) || 0;
                        let formattedScore = rawScore >= 100 ? `${Math.floor(rawScore - 100)}/100` : `${Math.floor(rawScore)}/100`;
    
                        // Extract all parameter names dynamically
                        criticalParams.forEach((param: { name: string }) => {
                            if (!allParameterNames.includes(param.name)) {
                                allParameterNames.push(param.name);
                            }
                        });
    
                        // Function to get score ratio
                        const getScoreRatio = (paramName: string) => {
                            const param = criticalParams.find((p: { name: string }) => p.name.toLowerCase() === paramName.toLowerCase());
                            return param?.score_ratio || "0/0";
                        };
    
                        // Construct row object
                        let row: any = {
                            ID: item.id,
                            position_rank: attributes.rank,
                            job_title: attributes.job_title,
                            total_score: formattedScore,
                            financial_impact: getScoreRatio("Financial Impact"),
                            reputation: getScoreRatio("Reputation"),
                            scarcity: getScoreRatio("Scarcity"),
                            strategic_impact: getScoreRatio("Strategic Impact"),
                        };
    
                        // Add all additional parameters dynamically
                        allParameterNames.forEach((paramName) => {
                            row[paramName] = getScoreRatio(paramName);
                        });
    
                        return row;
                    });
    
                // Pagination Logic
                const startIndex = (this.state.page - 1) * this.state.perPage;
                const paginatedData = CriticalTableData.slice(startIndex, startIndex + this.state.perPage);
    
                this.setState({
                    CriticalTableData: paginatedData,
                    TotalRecord: CriticalTableData.length,
                    PaginationPageCount: Math.ceil(CriticalTableData.length / this.state.perPage),
                    isLoading: false,
                    AllParameterNames: allParameterNames, // Store dynamic parameter names for the table
                });
            }
        }
    };


    NaviGateToAnyPage = (PageRoute: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PageRoute);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    NaviGateToEditAndViewPage = (PageName: string, item: { ID: string }) => {
        this.NaviGateToAnyPage(PageName)
        setStorageData("criticalID", item.ID)
    }

    AllCriticalPostionApiCall = () => {
        this.setState({
            isLoading: true,
        });
        const headers = {
            "token": this.state.token
        };

        const competencyMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetCriticalDashboardApiCallId = competencyMsg.messageId;
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        competencyMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.criticalEndPoint}?page=${this.state.page}&per_page=${this.state.perPage}`);
        competencyMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(competencyMsg.id, competencyMsg);
    }


    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        } else {
            return key
        }
    }



    handleCondition = (condition: boolean, truePart: React.ReactNode, falsePart: React.ReactNode) => {
        return condition ? truePart : falsePart
    }

    formatDateRange = (dateRange: [Date, Date] | null): string => {
        if (Array.isArray(dateRange) && dateRange.length === 2) {
            const [startDate, endDate] = dateRange;
            return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
        }
        return this.TraslationFuncation("CompetencyDashboard.SelectStartDateEndDate");
    };

    openCalendar = () => {
        this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
    };

    handleDateChange = (newDate: [Date, Date]) => {
        if (newDate) {
            this.setState({ tempDate: newDate });
        }
    };

    handleCalendarCancelBtn = () => {
        this.setState({ tempDate: null, selectedDate: null, calendarOpen: false, page: 1 },
            () => {  }
        );
    };

    handleCalendarSaveBtn = () => {
        if (this.state.tempDate) {
            this.setState({
                selectedDate: this.state.tempDate,
                tempDate: null,
                calendarOpen: false,
                page: 1
            }, () => {  });
        }
    };

    PaginationFuncation = (_event: React.ChangeEvent<unknown>, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.AllCriticalPostionApiCall(); // Fetch new data on page change
        });
    };


    // Customizable Area End

}