import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React, { RefObject } from "react";
import { i18n } from "i18next";
interface DeleteQuestionResponse {
    message?: string;
    error?: string;
}

export interface Questions {
    data: Array<{
        id: string;
        type: string;
        attributes: {
            id: string;
            text: string;
            answer_type: string;
            question_bank_id: string;
            question_format_name: string;
            difficulty_level: {
                id: string;
                name: string;
            },
            answer: {
                id: string;
                answer_type: string;
                text_response: string;
                choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
                correct_choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
            };
        };
    }>;
}

interface AssessmentResponse {
    assessment_id: number;
    attempted_assessment_id: number;
    questions: {
        id: number;
        text: string;
        options: {
            id: number;
            option_text: string;
        }[];
    }[];
    error: string
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    questionBankId: string;
    questionBankData: {};
    deleteQuestionData: DeleteQuestionResponse;
    errorMsg: string;
    previewClicked: boolean;
    loader: boolean;
    deleteDialog: boolean;
    rowId: string;
    questionFormatName: string;
    QuestionAnswerData: Array<{
        Index: number;
        Id: number;
        QuestionFormatName: string;
        QuestionText: string;
        Answers: {
            Id: number;
            ChoiceText: string;
        }[];
    }>;
    QuestionTypes: Array<{
        id: string,
        name: string
    }>;
    DifficultyLevels: Array<{
        id: string,
        name: string
    }>;
    FocusAreas: Array<{
        id: string,
        name: string
    }>;
    SuccessModel: boolean;
    AddQuestionsPopUp: boolean;
    currentPage: number;
    visibleCount: number,
    itemsPerPage: number,
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CandidateTestController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getQuestionListApiCallId: string = "";
    SaveChangesApiId: string = "";
    GetAllQuetions: string = "";
    previewRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            questionBankId: "",
            questionBankData: {},
            deleteQuestionData: {},
            previewClicked: false,
            loader: false,
            deleteDialog: false,
            rowId: "",
            questionFormatName: "",
            QuestionAnswerData: [],
            QuestionTypes: [],
            DifficultyLevels: [],
            FocusAreas: [],
            SuccessModel: false,
            AddQuestionsPopUp: false,
            currentPage: 0,
            visibleCount: 5,
            itemsPerPage: 5,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.GetAllQuetions) {
                this.questionListApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const questionBankId = await getStorageData("assessmentId");
        const parsedId = await JSON.parse(questionBankId)
        this.setState({ questionBankId: parsedId }, () => {
            // this.QuestionListApiCall()
            this.GetAllQuetionCall()
        });

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
    }

    questionListApiResponse = (responseJson: AssessmentResponse) => {
        
        const QuestionAnswerData = Array.isArray(responseJson.questions) ? responseJson.questions.map((item, index) => {
            return {
                Index: index + 1,
                Id: item.id,
                QuestionFormatName: "",
                QuestionText: item.text,
                Answers: item.options.map((Op) => {
                    return {
                        Id: Op.id,
                        ChoiceText: Op.option_text,
                    }
                }),
            }
        }) : []

        if (responseJson.error === "You have already started or completed this assessment") {
            this.NaviGateToAnyScreen("CandidateDashboard")
        }

        this.setState({
            loader: false,
            QuestionAnswerData: QuestionAnswerData,
        })
    }

    GetAllQuetionCall = () => {
        const headers = {
            "token": this.state.token,
            "Content-Type": "application/json",
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetAllQuetions = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_assessmenttest/attempted_assessments/start_test"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "assessment_id": 134
            })
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    NaviGateToAnyScreen = (PathName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PathName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    TrueOrFalseConditionPass = (Condition: boolean, TrueCondition: string, FalseCondition: string) => {
        if (Condition) {
            return TrueCondition
        } else {
            return FalseCondition
        }
    }

    PaginationData = () => {
        const { currentPage, visibleCount } = this.state;

        const currentData = this.state.QuestionAnswerData.slice(0, visibleCount);

        return currentData
    }

    handleNext = () => {
        this.setState((prevState) => ({
            visibleCount:
                prevState.visibleCount + prevState.itemsPerPage <= prevState.QuestionAnswerData.length
                    ? prevState.visibleCount + prevState.itemsPerPage
                    : prevState.QuestionAnswerData.length,
        }));
    };

    handleBack = () => {
        this.setState((prevState) => ({
            visibleCount:
                prevState.visibleCount - prevState.itemsPerPage >= prevState.itemsPerPage
                    ? prevState.visibleCount - prevState.itemsPerPage
                    : prevState.itemsPerPage,
        }));
    };

    // Customizable Area End
}