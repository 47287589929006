import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Card,
    CardContent
} from "@mui/material";
import { DashboardHeaderWeb as DashboardHeader } from "../../dashboard/src/DashboardHeader.web";
import { blankCheckbox, selectedCheckbox } from './assets';

import CandidateTestController, {
    Props
} from "./CandidateTestController";
import { withTranslation } from "react-i18next";
import { MainWrapper, Wrapper } from "./ViewGenerateAssessment.web";
// Customizable Area End

export default class CandidateTest extends CandidateTestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start






    questionList = (item: {
        Index: number;
        Id: number;
        QuestionFormatName: string;
        QuestionText: string;
        Answers: {
            Id: number;
            ChoiceText: string;
        }[];
    }, index: number) => {
        return (
            <Wrapper sx={{ marginTop: 0 }}>
                <Box sx={{
                    margin: {
                        xs: "24px 0px 24px 8px",
                        sm: "24px 0px 24px 16px",
                        md: "24px 0px 24px 40px"
                    }
                }}>
                    <Typography className="questionLabel">
                        {index}. {item.QuestionText}
                    </Typography>

                    <Box sx={{ fontFamily: "Urbanist" }}>
                        {item.QuestionFormatName === "Scenario Based" ? (
                            <Box className="optionsStyle scenarioAns">
                                <Typography>
                                    {item.Answers[0].ChoiceText}
                                </Typography>
                            </Box>
                        ) : (
                            item.Answers?.map((options, index) => (
                                <Box
                                    key={options.ChoiceText + index}
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                    className={"optionsStyle"}
                                    sx={{ fontFamily: "Urbanist" }}
                                    data-test-id="question_options"
                                >
                                    <img
                                        src={true ? selectedCheckbox.default : blankCheckbox.default}
                                        alt="checkbox"
                                        className="checkBoxStyle"
                                    />
                                    <Typography style={{
                                        marginRight: this.TrueOrFalseConditionPass(this.state.selectedLanguage.toLowerCase() === "english", "  ", " 10px ")
                                    }} >{options.ChoiceText}</Typography>
                                </Box>
                            ))
                        )}
                    </Box>
                </Box>

            </Wrapper>
        )
    }

    questionListCard = () => {
        return (
            <Wrapper style={{ marginTop: "0px" }}>
                {!this.state.loader &&
                    <Card sx={{ borderRadius: 2, borderLeft: '4px solid #CBD5E1' }}>
                        <CardContent sx={{ paddingLeft: "40px", paddingBottom: "0px !important" }}>
                            <Grid container spacing={1}>
                                {Array.isArray(this.state.QuestionAnswerData) && this.PaginationData().map((item, index) => (
                                    <Grid item xs={12} key={item.Id + index + 1}>
                                        {this.questionList(item, index + 1)}
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ borderTop: "2px solid #CBD5E1", margin: "16px" }}>
                                    <Box style={{ marginTop: "20px" }}>
                                        <Grid container alignItems={"center"} justifyContent={"space-between"}>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" className="questionLabel" style={{ marginBottom: "0px" }}>
                                                    Q: {String(this.state.visibleCount).padStart(2, "0")}/
                                                    {String(this.state.QuestionAnswerData.length).padStart(2, "0")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Grid item xs={6}>
                                                        <Button fullWidth className="addQuestionBtn" onClick={() => { this.handleBack() }}>
                                                            <Typography className="addQuestionText">
                                                                back
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            className="previewBtn"
                                                            data-test-id="preview_btn"
                                                            onClick={() => { this.handleNext() }}
                                                        >
                                                            <Typography className="previewBtnText">
                                                                {this.state.visibleCount === this.state.QuestionAnswerData.length ? "Submit" : "Next"}
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>

                    </Card>
                }
            </Wrapper>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <AssessmentWrapper>
                <DashboardHeader selectedMenu="Question banks" navigation={this.props.navigation} id={this.props.id}>
                    <MainWrapper ref={this.previewRef} style={{ direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl" }}>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={11}>
                                <Grid container alignItems={"center"} justifyContent={"space-between"} spacing={1}>
                                    <Grid item xs={11} sm={11} md={7}>
                                        <Grid container spacing={1} alignItems={"center"}>
                                            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                                                <Typography className="AssessmentName">
                                                    Assessment 01
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={5} lg={2.5}
                                        sx={{
                                            display: "flex",
                                            justifyContent: {
                                                xs: "flex-start",
                                                md: "flex-end",
                                                lg: "flex-end",
                                                xl: "flex-end"
                                            },
                                            marginRight: "16px",
                                            marginBottom: "16px"
                                        }}
                                    >
                                        {!this.state.previewClicked &&
                                            <Grid container spacing={2} justifyContent={"flex-end"}>
                                                <Button variant="text" className="ExitAssessmentText">
                                                    Exit Assessment
                                                </Button>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={11}>
                                {this.questionListCard()}
                            </Grid>
                        </Grid>
                    </MainWrapper>
                </DashboardHeader>
            </AssessmentWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const CandidateTestWeb = withTranslation()(CandidateTest);

const AssessmentWrapper = styled(Box)({
    "& .AssessmentName": {
        fontFamily: 'Urbanist',
        fontWeight: 700,
        fontSize: '14px',
        color: "#044352"
    },
    "& .ExitAssessmentText": {
        fontFamily: 'Urbanist',
        fontWeight: 400,
        fontSize: '14px',
        textTransform: "none", color: "#0F172A"
    }
});

// Customizable Area End