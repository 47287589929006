import React from "react";
// Customizable Area Start
import {
    Box,
    Grid,
    styled,
    Typography,
    Button,
    Hidden,
    IconButton,
    alpha,
    InputBase,
    TextField,
    InputAdornment,
    Backdrop,
    CircularProgress
} from "@mui/material";
import { DashboardSidebarWeb as DashboardSidebar } from './DashboardSidebar.web';
import MenuIcon from '@mui/icons-material/Menu';
import { rightArrow, notifications, defaultUserImg, closeSidebarArrow, viewProfileImg, profileSettingImg } from "./assets";
import { withTranslation } from "react-i18next";
import SearchIcon from '@mui/icons-material/Search';
// Customizable Area End

import DashboardHeaderController, {
    Props,
} from "./DashboardHeaderController";

export default class DashboardHeader extends DashboardHeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    headerText = () => {
        let text;
        let subText;
        switch (this.props.selectedMenu) {
            case 'All users':
                text = this.TraslationFuncation("dashboard.allUsers");
                subText = this.props.i18n?.t("dashboard.allUsersSubText");
                break;
            case 'Competencies':
                text = this.props.i18n?.t("dashboard.CompetencyManagement");
                subText = this.props.i18n?.t("dashboard.CompetencyManagementSubText");
                break;
            case 'Critical position':
                text = this.props.i18n?.t("dashboard.CriticalPositions");
                subText = this.props.i18n?.t("dashboard.CriticalPositionsSubText");
                break;
            case 'Question banks':
                text = this.props.i18n?.t("dashboard.QuestionBankDashboard");
                subText = this.props.i18n?.t("dashboard.QuestionBankDashboardSubText");
                break;
            case 'Assessments':
                text = this.TraslationFuncation("dashboard.AssessmentOverview");
                subText = this.TraslationFuncation("dashboard.AssessmentOverviewSubText");
                break;
            default:
                if (this.state.userProfileData.data?.attributes.full_name !== undefined && this.IsHrAdmin()) {
                    text = `${this.props.i18n?.t("dashboard.hello")}  ${this.state.userProfileData.data?.attributes.full_name} ${String.fromCodePoint(0x1F929)}`;
                } else {
                    text = "Dashboard"
                }
                if ((this.IsHrAdmin())) {
                    subText = this.props.i18n?.t("dashboard.subText")
                } else {
                    subText = "Invest in yourself. Unlock Your Next Career Opportunity."
                }
                break;
        }

        return (
            <>
                <Typography className="userNameTitle">
                    {text}
                </Typography>
                <Typography className="userDescription">
                    {subText}
                </Typography>
            </>
        );
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        }else{
            return key
        }
    }

    SearchContainer = () =>{
        return !this.IsHrAdmin() &&
        <TextField
            className="searchBox"
            data-test-id="searchTextBox"
            fullWidth
            value={this.state.SeachText}
            placeholder={"Search"}
            onChange={(e) => {
                if (this.props.SearchFuncation) {
                    this.props.SearchFuncation(e)
                    this.setState({
                        SeachText: e.target.value
                    })
                }
            }}
            onKeyUp={(e)=>  {
                if (e.key === 'Enter') {
                    if (this.props.CallSearchFuncation) {
                        this.props.CallSearchFuncation()
                    }
                }
            }}
        />
    }


    RenderNew = () =>{
        return this.state.profileDropdown && (
            <Box className="dropdownMenu profileDropdownMenu">
                <Typography
                    data-test-id="view_profile"
                    className={this.state.selectedLanguage.toLowerCase() === "english" ? "dropdownItem" : "dropdownItemArabic"}
                    onClick={this.navigateToUserProfile}
                >
                    <img
                        src={viewProfileImg.default}
                        alt="View Profile"
                        className={this.state.selectedLanguage.toLowerCase() === "english" ? "viewProfileImgStyle" : "viewProfileImgStyleAr"}
                    />
                    {this.props.i18n?.t("dashboard.viewProfile")}
                </Typography>
                <Typography
                    onClick={this.navigateToSetting}
                    data-test-id="settings"
                    className={this.state.selectedLanguage.toLowerCase() === "english" ? "dropdownItem" : "dropdownItemArabic"}
                >
                    <img
                        src={profileSettingImg.default}
                        alt="Profile Setting"
                        className={this.state.selectedLanguage.toLowerCase() === "english" ? "viewProfileImgStyle" : "viewProfileImgStyleAr"}
                    />
                    {this.props.i18n?.t("dashboard.setting")}
                </Typography>
            </Box>
        )
    }

    header = () => {
        return (
            <Wrapper>
                <Grid container className="headerWrapper" justifyContent="center"
                    sx={{
                        direction: this.state.selectedLanguage.toLowerCase() === "english" ? "ltr" : "rtl"
                    }}>
                    <Grid item xs={11}>
                        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                            <Grid item xs={12} lg={6}>
                                {this.headerText()}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Grid container
                                    alignItems="center"
                                    sx={{
                                        justifyContent: {
                                            xs: 'flex-start',
                                            lg: 'flex-end',
                                        },
                                    }}
                                    spacing={2}>
                                    <Grid item xs={5}>
                                        {this.SearchContainer()}
                                    </Grid>
                                    <Grid item xs={3} data-test-id="close_dropdown"
                                        onBlur={this.closeDropdown}
                                        className="langPositon">
                                        <Button className="langButton" data-test-id="lang_dropdown" onClick={this.toggleLanguageDropdown}>
                                            {this.state.selectedLanguage}
                                            <img
                                                src={rightArrow.default}
                                                alt="Image"
                                                className={`${this.state.selectedLanguage.toLowerCase() === "english" ? "englishArrow" : "arabicArrow"}`}
                                            />
                                        </Button>
                                        {this.state.languageDropdown && (
                                            <Box className="dropdownMenu">
                                                <Typography data-test-id="englishLang"
                                                    className="dropdownItem"
                                                    onMouseDown={(event) => {
                                                        event.preventDefault();
                                                        this.languageChange("English")
                                                    }}>
                                                    English
                                                </Typography>
                                                <Typography data-test-id="arabicLang"
                                                    className="dropdownItem"
                                                    onMouseDown={(event) => {
                                                        event.preventDefault();
                                                        this.languageChange("عربي")
                                                    }}>
                                                    عربي
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={1}>
                                        <img src={notifications.default} alt="Notifications" className="notifications" />
                                    </Grid>
                                    <Grid item xs={2} tabIndex={0} data-test-id="close_profile_dropdown" onBlur={this.closeProfileDropdown} className="langPositon">
                                        <Box className="profileBox" data-test-id="profile_dropdown" onClick={this.toggleProfileDropdown}>
                                            <img src={this.state.userProfileData.data?.attributes.photo || defaultUserImg.default}
                                                alt="Default User"
                                                className={this.state.userProfileData.data?.attributes.photo ? "uploadedUserImg" : "defaultUserImg"}
                                            />
                                        </Box>
                                        {this.RenderNew()}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Wrapper>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            this.state.Isloading ? <LoaderWraper>
                    <Backdrop className={"backdrop"} open={this.state.Isloading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </LoaderWraper> :
            <Grid container>
                {this.state.sideMenuVisible &&
                    <Grid item xs={12} md={2}
                        sx={{
                            zIndex: {
                                xs: 1000,
                                md: 1,
                            },
                            position: {
                                xs: "absolute",
                                md: "static",
                            },
                            backgroundColor: {
                                xs: "white",
                                md: "none",
                            },
                            width: {
                                xs: "200px",
                                md: "auto",
                            },
                        }}>
                        <DashboardSidebar
                            sideMenuVisible={this.state.sideMenuVisible}
                            toggleSideMenu={this.toggleSideMenu}
                            selectedMenu={this.props.selectedMenu}
                            navigation={this.props.navigation}
                            id={this.props.id} />
                    </Grid>
                }
                <Hidden mdDown>
                    {!this.state.sideMenuVisible &&
                        <Grid item>
                            <HeaderWrapper>
                                <img
                                    className="closeSidebarArrowMargin"
                                    src={closeSidebarArrow.default}
                                    alt="Open Sidebar"
                                    data-test-id="open_sidebar"
                                    onClick={this.toggleSideMenu} />
                            </HeaderWrapper>
                        </Grid>
                    }
                </Hidden>
                <Hidden mdUp>
                    <Grid item>
                        <HembergerWrapper>
                            <MenuIcon onClick={this.toggleSideMenu} />
                        </HembergerWrapper>
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={this.state.sideMenuVisible ? 10 : 12}>
                    <Grid container>
                        <Grid item xs={12}>
                            {this.header()}
                        </Grid>
                        <Grid item xs={12}>
                            {this.props.children}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const DashboardHeaderWeb = withTranslation()(DashboardHeader)

const LoaderWraper = styled(Box)({
    "& .backdrop": {
      color: '#044352',
      backgroundColor: "#fff",
    },
})
export const Wrapper = styled(Box)({
    borderBottom: "1px solid rgba(236, 236, 236, 1)",
    "& .headerWrapper": {
        padding: "27px 17px 27px 37px",
    },
    "& .userNameTitle": {
        fontFamily: "Urbanist",
        fontSize: "25px",
        fontWeight: 600,
        lineHeight: "30px",
        color: "#000104"
    },
    "& .userDescription": {
        fontFamily: "Urbanist",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#8A96A8",
        marginTop: "8px"
    },
    "& .langButton": {
        width: "50%px",
        maxWidth: "120px",
        height: "55px",
        padding: "0px 16px",
        borderRadius: "4px",
        border: "1px solid #ECECEC",
        opacity: "0px",
        backgroundColor: "#F1F5F9",
        textTransform: "none",
        fontFamily: "Urbanist",
        fontsize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#64748B",
        textAlign: "center"
    },
    "& .arabicArrow": {
        margin: "8px 3px 0px 0px",
        transform: "rotate(180deg)"
    },
    "& .englishArrow": {
        margin: "0px 0px 3px 8px"
    },
    "& .notifications": {
        height: "30px",
        width: "30px"
    },
    "& .profileBox": {
        height: "60px",
        width: "60px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F1F5F9",
        border: "1px solid #ECECEC",
    },
    "& .defaultUserImg": {
        height: "30px",
        width: "30px"
    },
    "& .uploadedUserImg": {
        height: "60px",
        width: "60px",
        border: "1px solid rgb(236, 236, 236)",
        borderRadius: "50%"
    },
    "& .dropdownMenu": {
        position: "absolute",
        marginTop: "4px",
        display: "flex",
        flexDirection: "column",
        right: "0px",
        background: "white",
        border: "1px solid #ccc",
        borderRadius: "8px",
        zIndex: 1000,
        maxWidth: "192px",
        width: "120%",
        boxShadow: "2px 8px 32px 2px #0000000F",
        "@media(max-width:960px)": {
            width: "80%",
        },
    },
    "& .profileDropdownMenu": {
        minWidth: "170px"
    },
    "& .langPositon": {
        position: "relative"
    },
    "& .dropdownItem": {
        display: "flex",
        padding: "4px 16px",
        cursor: "pointer",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#0F172A",
        borderRadius: "8px",
        margin: "2px"
    },
    "& .dropdownItemArabic": {
        display: "flex",
        padding: "4px 3px",
        cursor: "pointer",
        fontFamily: "Urbanist",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#0F172A",
        borderRadius: "8px",
        margin: "2px"
    },
    "& .dropdownItem:hover": {
        backgroundColor: "#E2E8F0",
        padding: "4px 16px",
        margin: "2px"
    },
    "& .dropdownItemArabic:hover": {
        backgroundColor: "#E2E8F0",
        padding: "4px 3px",
        margin: "2px"
    },
    "& .viewProfileImgStyle": {
        height: "18px",
        width: "18px",
        margin: "2px 8px 0px 0px"
    },
    "& .viewProfileImgStyleAr": {
        height: "18px", width: "18px",
        margin: "2px 8px 0px 6px"
    }
})
export const HeaderWrapper = styled(Box)({
    borderRight: "1px solid rgba(236, 236, 236, 1)",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: "white",
    zIndex: 10,
    position: "absolute",
    padding: "8px",
    "& .closeSidebarArrowMargin": {
        marginTop: "36px"
    },
})
export const HembergerWrapper = styled(Box)({
    zIndex: 10,
    padding: "12px"
})

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: '100%',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    padding: "10px 16px",
    color: 'inherit',
    '& .MuiInputBase-input': {
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));
// Customizable Area End