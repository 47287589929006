import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React, { RefObject } from "react";
import { i18n } from "i18next";
interface DeleteQuestionResponse {
    message?: string;
    error?: string;
}

export interface Questions {
    data: Array<{
        id: string;
        type: string;
        attributes: {
            id: string;
            text: string;
            answer_type: string;
            question_bank_id: string;
            question_format_name: string;
            difficulty_level: {
                id: string;
                name: string;
            },
            answer: {
                id: string;
                answer_type: string;
                text_response: string;
                choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
                correct_choices: {
                    id: string;
                    option_text: string;
                    correct: boolean;
                }[];
            };
        };
    }>;
}

interface AssessmentResponse {
    message: string;
    assessment: {
        data: {
            id: string;
            type: string;
            attributes: {
                id: number;
                title: string;
                time_allocation: number;
                objective: string;
                number_of_questions: number;
                competencies: {
                    id: number;
                    job_title: string;
                    description: string;
                    competency_name: string;
                    job_description_id: number;
                    created_at: string;
                    updated_at: string;
                    competency_type_id: number;
                    status: boolean;
                    competency_dictionary_id: number;
                }[];
                status: string;
                creator: {
                    id: number;
                    name: string;
                };
                difficulty_levels: {
                    id: number;
                    name: string;
                }[];
                focus_areas: {
                    id: number;
                    name: string;
                }[];
                question_formats: {
                    id: number;
                    name: string;
                }[];
                job_description: {
                    id: number;
                    job_title: string;
                };
                questions: {
                    data: {
                        id: string;
                        type: string;
                        attributes: {
                            id: number;
                            text: string;
                            question_format_name: string;
                            question_bank_id: number;
                            assessment_id: number;
                            created_at: string;
                            job_description: {
                                id: number;
                                name: string;
                            };
                            competencies: [];
                            difficulty_level: {
                                id: number;
                                name: string;
                            };
                            answer: {
                                id: number;
                                answer_type: string;
                                choices: {
                                    id: number;
                                    option_text: string;
                                    correct: boolean;
                                }[];
                                correct_choices: {
                                    id: number;
                                    option_text: string;
                                    correct: boolean;
                                }[];
                                text_response: string;
                            };
                        };
                    };
                }[];
            };
        };
    };
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    i18n: i18n;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: string;
    token: string;
    questionBankId: string;
    questionBankData: {};
    deleteQuestionData: DeleteQuestionResponse;
    errorMsg: string;
    previewClicked: boolean;
    loader: boolean;
    deleteDialog: boolean;
    rowId: string;
    questionFormatName: string;
    QuestionAnswerData: Array<{
        Id: string;
        Index: number;
        QuestionFormatName: string,
        QuestionText: string;
        TagText: Array<{
            id: string;
            name: string;
        }>
        Answers: Array<{
            Id: number
            ChoiceText: string;
            isCorrect: boolean;
        }>;
        CorrectChoices: Array<{
            Correctid: number;
            CorrectText: string;
        }>;
    }>;
    QuestionTypes: Array<{
        id: string,
        name: string
    }>;
    DifficultyLevels: Array<{
        id: string,
        name: string
    }>;
    FocusAreas: Array<{
        id: string,
        name: string
    }>;
    SuccessModel: boolean;
    AddQuestionsPopUp: boolean;
    NewData: string
    // Customizable Area End

}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ViewGenerateAssessmentController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getQuestionListApiCallId: string = "";
    deleteQuestionApiCallId: string = "";
    SaveChangesApiId: string = "";
    previewRef: RefObject<HTMLElement> = React.createRef<HTMLElement>();
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            selectedLanguage: "",
            token: "",
            errorMsg: "",
            questionBankId: "",
            questionBankData: {},
            deleteQuestionData: {},
            previewClicked: false,
            loader: false,
            deleteDialog: false,
            rowId: "",
            questionFormatName: "",
            QuestionAnswerData: [],
            QuestionTypes: [],
            DifficultyLevels: [],
            FocusAreas: [],
            SuccessModel: false,
            AddQuestionsPopUp: false,
            NewData: "",
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getQuestionListApiCallId) {
                this.questionListApiResponse(responseJson)
            }
            if (apiRequestCallId === this.deleteQuestionApiCallId) {
                this.deleteQuestionApiResponse(responseJson)
            }
            this.SaveChangeApiCallResponse(apiRequestCallId,responseJson)
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();

        const signInResponse = await getStorageData("signInResponse");
        const parsedSignInResponse = await JSON.parse(signInResponse)
        this.setState({ token: parsedSignInResponse.meta?.token })

        const questionBankId = await getStorageData("assessmentId");
        const parsedId = await JSON.parse(questionBankId)
        this.setState({ questionBankId: parsedId }, () => {
            this.QuestionListApiCall()
        });

        const language = await getStorageData("language") || "English";
        this.setState({ selectedLanguage: language });
    }

    questionListApiResponse = (responseJson: AssessmentResponse) => {
        
        const QuestionAnswerData = responseJson.assessment.data.attributes.questions.map((item, index) => {
            return {
                Index: index + 1,
                Id: item.data.id,
                QuestionFormatName: item.data.attributes.question_format_name,
                QuestionText: item.data.attributes.text,
                TagText: [{
                    id: `${item.data.attributes.difficulty_level.id}`,
                    name: item.data.attributes.difficulty_level.name.toLowerCase()
                }],
                Answers: item.data.attributes.answer.answer_type === "Multiple Choice" ? item.data.attributes.answer.choices?.map((item) => {
                    return {
                        Id: item.id,
                        ChoiceText: item.option_text,
                        isCorrect: item.correct
                    }
                }) : [{
                    Id: item.data.attributes.answer.id,
                    ChoiceText: item.data.attributes.answer.text_response,
                    isCorrect: true
                }],
                CorrectChoices: item.data.attributes.answer.correct_choices?.map((CorrectItem) => {
                    return {
                        Correctid: CorrectItem.id,
                        CorrectText: CorrectItem.option_text,
                    }
                }),
            }
        })
        let QuestionTypes = responseJson.assessment.data.attributes.question_formats.map((QItem) => {
            return {
                id: `${QItem.id}`,
                name: QItem.name
            }
        })
        const DifficultyLevels = responseJson.assessment.data.attributes.difficulty_levels.map((item) => {
            return {
                id: `${item.id}`,
                name: item.name
            }
        })
        const FocusAreas = responseJson.assessment.data.attributes.focus_areas.map((item) => {
            return {
                id: `${item.id}`,
                name: item.name
            }
        })
        this.setState({
            questionBankData: responseJson.assessment,
            loader: false,
            QuestionAnswerData: QuestionAnswerData,
            QuestionTypes: QuestionTypes,
            DifficultyLevels: DifficultyLevels,
            FocusAreas: FocusAreas,
        })
    }

    deleteQuestionApiResponse = (responseJson: DeleteQuestionResponse) => {
        if (responseJson && !responseJson.error) {
            this.setState({
                deleteQuestionData: responseJson,
                deleteDialog: false,
                rowId: ""
            }, () => {
                this.QuestionListApiCall();
            })
        } else {
            this.setState({
                errorMsg: responseJson.error || "",

            });
        }
    }


    QuestionListApiCall = () => {
        this.setState({ loader: true })
        const headers = {
            "token": this.state.token
        };
        const getQuestionListMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getQuestionListApiCallId = getQuestionListMsg.messageId;
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.GetGenratedAssessment(this.state.questionBankId),
        );
        getQuestionListMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(getQuestionListMsg.id, getQuestionListMsg);
    }

    deleteQuestionApiCall = async (questionID: string) => {
        const headers = {
            "token": this.state.token
        };
        const deleteQuestionMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteQuestionApiCallId = deleteQuestionMsg.messageId;
        deleteQuestionMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        deleteQuestionMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDeleteAssessmentQuestionUrl(this.state.questionBankId, questionID)
        );
        deleteQuestionMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(deleteQuestionMsg.id, deleteQuestionMsg);
    }

    navigateToListScreen = () => {
        this.setState({ previewClicked: !this.state.previewClicked })
    }

    scrollToSection = (ref: React.RefObject<HTMLElement>) => {
        this.setState({ previewClicked: true }, () => {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth' });
            }
        })
    };

    handleDeleteClose = () => {
        this.setState({ deleteDialog: false })
    }

    handleDeleteRow = (id: string) => {
        this.setState({ deleteDialog: true, rowId: id })
    }

    navigateToCreation = () => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "QuestionBankDashboard");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }
    navigateToEdit = async (id: string) => {
        await setStorageData("editQuestionId", JSON.stringify(id))
        setStorageData("NaviGateFrom", "ViewGenerateAssessment")
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EditAseessmentQuestions");
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    AddQuestions = () =>{
        setStorageData("AddQuestions", this.state.questionBankId)
        setStorageData("FromPageName", "ViewGenerateAssessment");
        this.NaviGateToAnyScreen("UploadQuestions")
    }

    NaviGateToAnyScreen = (PathName: string) => {
        const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
        navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), PathName);
        navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateMsg);
    }

    SaveChangesApiCall = async () => {
        const AssessmentId = await getStorageData("assessmentId") as string
        this.setState({ loader: true, NewData: "" });
        const headers = {
            "token": this.state.token
        };
        const httpBody = {
            "status": "created"
        }
        const AssessmentSave = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.SaveChangesApiId = AssessmentSave.messageId;
        AssessmentSave.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        AssessmentSave.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.StatusChangeEndPoint}/${AssessmentId}`
        );
        AssessmentSave.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        )
        AssessmentSave.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PUT
        );
        runEngine.sendMessage(AssessmentSave.id, AssessmentSave);

    }

    SaveChangeApiCallResponse = (ApiCallId: string, Response: { message: string }) => {
        if (this.SaveChangesApiId === ApiCallId) {
            if (Response.message === "Assessment update successfully") {
                this.setState({ loader: false, SuccessModel: true, NewData: "" });
            } else {
                this.setState({ loader: false });
            }
        }
    }

    CloseModelSuccessModel = () => {
        this.setState({ SuccessModel: false })
        this.NaviGateToAnyScreen("AssessmentTestDashbord")
    }

    TraslationFuncation = (key: string) => {
        if (this.props.i18n) {
            return this.props.i18n.t(key);
        } else {
            return key
        }
    }

    TrueOrFalseConditionPass = (Condition:boolean,TrueCondition:string,FalseCondition:string) =>{
        if (Condition) {
            return TrueCondition
        }else{
            return FalseCondition
        }
    }

    // Customizable Area End
}