// App.js - WEB
import React, { Component, useEffect } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import i18n from 'i18next';
import 'react-calendar/dist/Calendar.css';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet,useNavigate, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CandidateDashboard from "../../blocks/dashboard/src/CandidateDashboard.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import {DashboardWeb as Dashboard} from "../../blocks/dashboard/src/Dashboard.web";
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import {ViewCompetencyWeb as  ViewCompetency} from "../../blocks/cfchatgptcompetencymapgeneration/src/ViewCompetency.web";
import {ViewDictionaryWeb as ViewDictionary} from "../../blocks/cfchatgptcompetencymapgeneration/src/ViewDictionary.web";
import Dictionary from "../../blocks/cfchatgptcompetencymapgeneration/src/ViewDictionary";
import { CompetencyDashboardWeb as CompetencyDashboard } from "../../blocks/cfchatgptcompetencymapgeneration/src/CompetencyDashboard";
import Creation from "../../blocks/cfchatgptcompetencymapgeneration/src/DictionaryCreation";
import {DictionaryCreationWeb as DictionaryCreation} from "../../blocks/cfchatgptcompetencymapgeneration/src/DictionaryCreation.web";
import { CompetencyDictionaryDashboardWeb as CompetencyDictionaryDashboard} from "../../blocks/cfchatgptcompetencymapgeneration/src/CompetencyDictionaryDashboard.web";
import {CfchatgptcompetencymapgenerationWeb as  Cfchatgptcompetencymapgeneration} from "../../blocks/cfchatgptcompetencymapgeneration/src/Cfchatgptcompetencymapgeneration";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrationWeb, {EmailAccountRegistrationWebWeb} from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock, {EmailAccountLoginBlockWeb} from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfchatgptintegration9 , {Cfchatgptintegration9Web} from "../../blocks/cfchatgptintegration9/src/Cfchatgptintegration9.web";
import {JobDescriptionWeb as JobDescription} from "../../blocks/cfchatgptintegration9/src/JobDescription.web";
import EditDescription from "../../blocks/cfchatgptintegration9/src/EditJobDescription";
import DescriptionJob from "../../blocks/cfchatgptintegration9/src/JobDescription";
import { EditJobDescriptionWeb as EditJobDescription } from "../../blocks/cfchatgptintegration9/src/EditJobDescription.web";
import {ViewJobDescriptionWeb as ViewJobDescription} from "../../blocks/cfchatgptintegration9/src/ViewJobDescription.web";
import { QuestionsAddWeb as QuestionsAdd } from "../../blocks/cfchatgptintegrationforknowledgetest/src/QuestionsAdd.web";
import AddInAssessmentNative from "../../blocks/cfchatgptintegrationforknowledgetest/src/QuestionsAdd";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import { ContactUsWeb as Contactus } from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTest";
import {AssessmentTestDashbordWeb as  AssessmentTestDashbord} from "../../blocks/assessmenttest/src/AssessmentTestDashbord.web";
import {AssessmentTestDashbordWeb as  CriticalDashboard} from "../../blocks/multipageforms/src/CriticalPositionDashbord.web";
import { AssignAssessmentWeb as  AssignAssessment } from "../../blocks/assessmenttest/src/AssignAssessment.web";
import { EditAseessmentQuestionsWeb as  EditAseessmentQuestions } from "../../blocks/cfchatgptintegrationforknowledgetest/src/EditAseessmentQuestions.web";
import { ViewScoreCardWeb as  ViewScoreCard } from "../../blocks/assessmenttest/src/ViewScoreCard.web";
import { ViewAssessmentWeb as  ViewAssessment } from "../../blocks/assessmenttest/src/ViewAssessment.web";
import { EditAssessmentWeb as  EditAssessment } from "../../blocks/assessmenttest/src/EditAssessment.web";
import AssessmentEdit from "../../blocks/assessmenttest/src/EditAssessment";
import  AssessmentView from "../../blocks/assessmenttest/src/ViewAssessment";
import ViewCriticalDashboard from "../../blocks/multipageforms/src/ViewCriticalDashboard";
import CardViewScore from "../../blocks/assessmenttest/src/ViewScoreCard";
import AssessmentAssign from "../../blocks/assessmenttest/src/AssignAssessment";
import AssessmentTestDashbordView from "../../blocks/assessmenttest/src/AssessmentTestDashbord";
import CriticalDashboardView from "../../blocks/multipageforms/src/CriticalPositionDashbord";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import {UserProfileBasicBlockWeb  as UserProfileBasic} from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import { EditUserProfileWeb as EditUserProfile} from "../../blocks/user-profile-basic/src/EditUserProfile.web";
import { CompanyDetailsWeb as CompanyDetails } from "../../blocks/user-profile-basic/src/CompanyDetails.web";
import { ViewCompanyDetailsWeb as ViewCompanyDetails } from "../../blocks/user-profile-basic/src/ViewCompanyDetails.web";
import {AllUsersDashboardWeb as AllUsersDashboard} from "../../blocks/user-profile-basic/src/AllUsersDashboard.web";
import {UserCreationWeb as UserCreation} from "../../blocks/user-profile-basic/src/UserCreation.web";
import QuestionBankDashboard from "../../blocks/questionbank/src/QuestionBankDashboard.web";
import UploadQuestions from "../../blocks/questionbank/src/UploadQuestions.web"
import upload from "../../blocks/questionbank/src/UploadQuestions"
import { GenerateAssessmentWeb as  GenerateAssessment} from "../../blocks/cfchatgptintegrationforknowledgetest/src/GenerateAssessment.web";
import { ViewGenerateAssessmentWeb as ViewGenerateAssessment } from "../../blocks/cfchatgptintegrationforknowledgetest/src/ViewGenerateAssessment.web";
import { CandidateTestWeb as CandidateTest } from "../../blocks/cfchatgptintegrationforknowledgetest/src/CandidateTest.web";
import  GenerateAssessmentView from "../../blocks/cfchatgptintegrationforknowledgetest/src/ViewGenerateAssessment";
import AssessmentGentaration from "../../blocks/cfchatgptintegrationforknowledgetest/src/GenerateAssessment";
import Questions from "../../blocks/questionbank/src/QuestionBankDashboard";
import {questionBankWeb as QuestionBankCreation} from "../../blocks/questionbank/src/QuestionBankCreation.web";
import ListingQuestionBank from "../../blocks/questionbank/src/ListingQuestionBank.web";
import ListQuestions from "../../blocks/questionbank/src/ListingQuestionBank";
import EditQuestionBank from "../../blocks/questionbank/src/EditQuestionBank.web";
import EditQuestion from "../../blocks/questionbank/src/EditQuestionBank"
import QuestionCreation from "../../blocks/questionbank/src/QuestionBankCreation";
import { ViewUserProfileWeb as ViewUserProfile } from "../../blocks/user-profile-basic/src/ViewUserProfile.web";
import Cfquestionnaireandpersonalizedprogramgeneration from "../../blocks/cfquestionnaireandpersonalizedprogramgeneration/src/Cfquestionnaireandpersonalizedprogramgeneration";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword, {ForgotPasswordWeb} from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import { Settings2Web as Settings2 } from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfpromptengineeringfordataworkflowintegration31 from "../../blocks/cfpromptengineeringfordataworkflowintegration31/src/Cfpromptengineeringfordataworkflowintegration31";
import Cfchatgptintegrationforknowledgetest from "../../blocks/cfchatgptintegrationforknowledgetest/src/Cfchatgptintegrationforknowledgetest";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import WelcomeScreen from "../../blocks/languagesupport/src/WelcomeScreen"
import { getStorageData } from '../../framework/src/Utilities';


const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
LandingPage:{
  component:LandingPageWeb,
  path:"/"
},
CriticalDashboard: {
  component: CriticalDashboard,
  path: "/CriticalDashboard"
},
CriticalDashboardView: {
  component: CriticalDashboardView,
  path: "/CriticalDashboardView"
},
Dashboard:{
  component:Dashboard,
  path:"/Dashboard"
},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Cfchatgptcompetencymapgeneration:{
 component:Cfchatgptcompetencymapgeneration,
path:"/Cfchatgptcompetencymapgeneration"},
CompetencyDashboard:{
  component:CompetencyDashboard,
 path:"/CompetencyDashboard"}, 
  Creation:{
  component:Creation,
 path:"/Creation"},
 DictionaryCreation:{
  component:DictionaryCreation,
 path:"/DictionaryCreation"}, 
 CompetencyDictionaryDashboard:{
  component:CompetencyDictionaryDashboard,
 path:"/CompetencyDictionaryDashboard"},
 ViewCompetency:{
  component:ViewCompetency,
 path:"/ViewCompetency"}, 
 ViewDictionary:{
  component:ViewDictionary,
 path:"/ViewDictionary"}, 
 Dictionary:{
  component:Dictionary,
 path:"/Dictionary"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountRegistrationWeb:{
  component:EmailAccountRegistrationWebWeb,
 path:"/EmailAccountRegistrationWeb"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlockWeb,
path:"/signin"},
EmailAccountRegistrationWeb:{
 component:EmailAccountRegistrationWebWeb,
path:"/signup"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cfchatgptintegration9:{
 component:Cfchatgptintegration9Web,
path:"/Cfchatgptintegration9"},
JobDescription:{
  component: JobDescription,
  path: "/JobDescription"
},
EditDescription:{
  component: EditDescription,
  path: "/EditDescription"
},
DescriptionJob:{
  component: DescriptionJob,
  path: "/DescriptionJob"
},
EditJobDescription:{
  component: EditJobDescription,
  path: "/EditJobDescription"
},
ViewJobDescription:{
  component: ViewJobDescription,
  path: "/ViewJobDescription"
},
QuestionsAdd:{
  component: QuestionsAdd,
  path: "/QuestionsAdd"
},
AddInAssessmentNative:{
  component: AddInAssessmentNative,
  path: "/AddInAssessmentNative"
},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
CandidateDashboard:{
  component: CandidateDashboard,
  path:"/CandidateDashboard"
},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
UserProfileBasic:{
  component:UserProfileBasic,
 path:"/UserProfileBasic"}, 
 EditUserProfile:{
  component:EditUserProfile,
 path:"/EditUserProfile"}, 
 CompanyDetails:{
  component:CompanyDetails,
 path:"/CompanyDetails"}, 
 AllUsersDashboard:{
  component:AllUsersDashboard,
 path:"/AllUsersDashboard"}, 
 UserCreation:{
  component:UserCreation,
 path:"/UserCreation"},
QuestionBankDashboard:{
  component:QuestionBankDashboard,
 path:"/QuestionBankDashboard"},  
 Questions:{
  component:Questions,
 path:"/Questions"}, 
 UploadQuestions:{
   component:UploadQuestions,
  path:"/UploadQuestions"},  
  upload:{
    component:upload,
   path:"/upload"},   
   GenerateAssessment:{
    component:GenerateAssessment,
   path:"/GenerateAssessment"},  
   ViewGenerateAssessment:{
    component:ViewGenerateAssessment,
   path:"/ViewGenerateAssessment"},  
   CandidateTest:{
    component:CandidateTest,
   path:"/CandidateTest"},  
   GenerateAssessmentView:{
    component:GenerateAssessmentView,
   path:"/GenerateAssessmentView"},  
   AssessmentGentaration:{
    component:AssessmentGentaration,
   path:"/AssessmentGentaration"},  
 QuestionBankCreation:{
  component:QuestionBankCreation,
 path:"/QuestionBankCreation"},  
 QuestionCreation:{
  component:QuestionCreation,
 path:"/QuestionCreation"}, 
 ListingQuestionBank:{
  component:ListingQuestionBank,
 path:"/ListingQuestionBank"},
 ListQuestions:{
  component:ListQuestions,
 path:"/ListQuestions"},
 EditQuestionBank:{
  component:EditQuestionBank,
 path:"/EditQuestionBank"},
 EditQuestion:{
  component:EditQuestion,
 path:"/EditQuestion"},
 ViewUserProfile:{
  component:ViewUserProfile,
 path:"/ViewUserProfile"}, 
 ViewCompanyDetails:{
  component:ViewCompanyDetails,
 path:"/ViewCompanyDetails"}, 
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
AssessmentTestDashbord:{
 component:AssessmentTestDashbord,
path:"/AssessmentTestDashbord"},
AssessmentTestDashbordView:{
 component:AssessmentTestDashbordView,
path:"/AssessmentTestDashbordView"},
AssignAssessment:{
 component:AssignAssessment,
path:"/AssignAssessment"},
EditAseessmentQuestions:{
 component:EditAseessmentQuestions,
path:"/EditAseessmentQuestions"},
ViewScoreCard:{
 component:ViewScoreCard,
path:"/ViewScoreCard"},
ViewAssessment:{
 component:ViewAssessment,
path:"/ViewAssessment"},
EditAssessment:{
 component:EditAssessment,
path:"/EditAssessment"},
AssessmentEdit:{
 component:AssessmentEdit,
path:"/AssessmentEdit"},
AssessmentView:{
 component:AssessmentView,
path:"/AssessmentView"},
ViewCriticalDashboard: {
  component: ViewCriticalDashboard,
  path: "/ViewCriticalDashboard"
},
CardViewScore:{
 component:CardViewScore,
path:"/CardViewScore"},
AssessmentAssign:{
 component:AssessmentAssign,
path:"/AssessmentAssign"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Cfquestionnaireandpersonalizedprogramgeneration:{
 component:Cfquestionnaireandpersonalizedprogramgeneration,
path:"/Cfquestionnaireandpersonalizedprogramgeneration"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
ForgotPassword:{
 component:ForgotPasswordWeb,
path:"/change_password"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfpromptengineeringfordataworkflowintegration31:{
 component:Cfpromptengineeringfordataworkflowintegration31,
path:"/Cfpromptengineeringfordataworkflowintegration31"},
Cfchatgptintegrationforknowledgetest:{
 component:Cfchatgptintegrationforknowledgetest,
path:"/Cfchatgptintegrationforknowledgetest"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');


function App() {
  const location = useLocation(); 
  const navigate = useNavigate();
  const isAuthenticate = async() => {
    const tokenMeta = await getStorageData('signInResponse', true);
    const token = tokenMeta?.meta?.token;
    const tokenRegex = /^[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/;
    const publicRoutes = ["/","/signin" , "/signup", "/change_password"];
    const isValidToken = token && tokenRegex.test(token);
    const isPublicRoute = publicRoutes.includes(location.pathname);
    if (!isValidToken && !isPublicRoute) {
      return navigate("/signin")
    }
    return true;
  }

  if (!isAuthenticate()) {
    return null;
  }

  useEffect(async () => {
    const language = await getStorageData("language");
    const selectedLanguage = language.toString().toLowerCase() === "91(J" ? "ar" : "en";

    await i18n.changeLanguage(selectedLanguage);
  }, []);

  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw', overflowX: "hidden"}}>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;